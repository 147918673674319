import md from "../../assets/images/wwwww.webp";
// import bas from "../../assets/images/bassey.webp";
import jonas from "../../assets/images/joanas 2.png";
import lola from "../../assets/images/lola.webp";
import kay from "../../assets/images/kayy.webp";
import seyi from "../../assets/images/seyi.webp";
import nico from "../../assets/images/nico.webp";
import alex from "../../assets/images/Alex2.webp";
import lawal from "../../assets/images/lawal.webp";
import abdul from "../../assets/images/abdul.webp";
import kola from "../../assets/images/kola.webp";
import Ezikiel from "../../assets/images/ezikiel.webp";
import keneth from "../../assets/images/Keneth.webp";

export const managementData = [
  { name: "Ayodeji Odunukan", title: "Managing Director Denukan", img: md },
  {
    name: "Ololade Odunukan",
    title: "Director of HR/Admin & Strategic Planning",
    img: lola,
  },
  {
    name: "Joanas Okafor",
    title: "Credit Control and Recovery Manager",
    img: jonas,
  },

  {
    name: "Obasekore Waheed Kola",
    title:
      "Managing Director Idi Igba Denukan Co-operative Investment Credit Society Limited",
    img: kola,
  },
  {
    name: "Adejoke Idowu Toye-Adbul",
    title: "Managing Director Beststar Microfinance Bank",
    img: abdul,
  },
  // {
  //   name: "Lawal Wasiu Omotayo ",
  //   title: "Managing Director Dorben Microfinance Bank",
  //   img: lawal,
  // },
  // {
  //   name: "Ezekiel Ajani ",
  //   title: "Head of Compliance",
  //   img: Ezikiel,
  // },
  {
    name: "Keneth Eneje ",
    title: "Head of Internal Control",
    img: keneth,
  },
];

export const teamLeadsData = [
  { name: "Ariyo Kayode Olugbenga", title: "Head of Accounts", img: kay },
  {
    name: "Anozie Amuneke Nicholas",
    title: "Head of IT Departmentg",
    img: nico,
  },
  {
    name: "Oluwaseyi Fatumole",
    title: "Lead Software Engineer",
    img: seyi,
  },
  { name: "Alex Oluwole", title: "Head of Admin", img: alex },
];
